import { Box, Checkbox, Chip, Grid, Input, Typography } from '@mui/joy';
import { Paper } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { PageContainer } from '@/src/BaseStyles';
import { MediaSkeleton } from '../Dashboard/Media';
import moment from 'moment';
import DashboardHeader from '../Dashboard/DashboardHeader';
import API from '@/src/api';
import ProductMedia from './ProductMedia';
import { useDebounce } from 'use-debounce';

const RightContainer = styled(Box)`
	position: sticky;
	top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex-basis: 350px;
	flex-shrink: 0;
	@media (max-width: 768px) {
		display: none;
	}
`;

const Container = styled(PageContainer)`
`;

const FeedContainer = styled(Grid)`
	flex-grow: 1;
`;

const ContentContainer = styled(Box)`
	display: grid;
	grid-template-columns: 350px 1fr;
	gap: 1rem;
	align-items: flex-start;
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;

const CheckBoxContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: .5rem;
`;

const ShinpadsDashboard = () => {
	const [products, setProducts] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const [searchValue] = useDebounce(search, 750);
	const [categories, setCategories] = React.useState<any[]>([]);
	const [afterDate, setAfterDate] = React.useState<string>(moment().format('YYYY-MM-DD'));
	const [beforeDate, setBeforeDate] = React.useState<string>(null);


	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const data = await API.getProducts({ search: searchValue });
			console.log(data);
			setProducts(data.products || []);
			setLoading(false);
		};
		getData();
	}, [searchValue]);


	const media = useMemo(() => {
		return products.map(p => <ProductMedia key={p.id} product={p} />);
	}, [products]);

	return (
		<Container>
			<DashboardHeader />
			<ContentContainer>
				<RightContainer>
					<Paper>
						<Typography level='title-lg'>Filters</Typography>
						<CheckBoxContainer>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('Goods')}
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'Goods']);
									} else {
										setCategories(categories.filter(c => c !== 'Goods'));
									}
								}}
								label={'Goods'}
							/>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('Services')}
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'Services']);
									} else {
										setCategories(categories.filter(c => c !== 'Services'));
									}
								}}
								label={'Services'}
							/>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('Construction')}
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'Construction']);
									} else {
										setCategories(categories.filter(c => c !== 'Construction'));
									}
								}}
								label={'Construction'}
							/>
						</CheckBoxContainer>
						<Box display='flex' gap={1} marginTop={2}>
							<Input
								type='date'
								variant='outlined'
								sx={{ background: 'transparent', boxShadow: 'none' }}
								fullWidth
								size='sm'
								placeholder='After Date'
								onChange={(e) => setAfterDate(e.target.value)}
								value={afterDate ? moment(afterDate).format('YYYY-MM-DD') : 'After'}
							/>
							<Input
								fullWidth
								variant='outlined'
								sx={{ background: 'transparent', boxShadow: 'none' }}
								size='sm'
								type='date'
								placeholder='Before Date'
								onChange={(e) => setBeforeDate(e.target.value)}
								value={beforeDate ? moment(beforeDate).format('YYYY-MM-DD') : 'Before'}
							/>
						</Box>
					</Paper>
				</RightContainer>
				<FeedContainer>
					<Box display='flex' gap={0.5} paddingBottom={1}>
						<Input
							placeholder='Medical supplies between April and May'
							variant='outlined'
							fullWidth
							size='lg'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							startDecorator={<Chip color='primary' size='sm'>AI Search</Chip>}
						/>
					</Box>
					<Paper style={{ padding: 0 }}>
						<Box display='flex' flexDirection='column'>
							{loading ? (
								<>
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
								</>
							) : (
								<>
									{media}
								</>
							)}
						</Box>
					</Paper>
				</FeedContainer>
			</ContentContainer>
		</Container>
	);
};

export default ShinpadsDashboard;
